/* You can add global styles to this file, and also import other style files */
@import 'style-declarations';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
}

html body.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

html .sntndr {
    margin: 0;
    margin-bottom: 150px;
    background: none;
    font-family: 'Open Sans, Regular', sans-serif;
    font-size: 12px;
    color: $textColorBlack;
    /*this class is used to hide components for web devices, and show for screen readers*/
}

html .sntndr .container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: table;
    width: 1170px;
    padding: 0 10px;
    margin: 0 auto;

    @media screen and (max-width: $desktop-min-size) {
        width: 100%;
    }
}

html .sntndr input[type=text] {
    font-family: 'Open Sans, Regular', sans-serif;
    position: relative;
    z-index: 0;
    border: none;
    padding: 0 10px;
    font-size: 16px;
    color: $textColorBlack;
    box-shadow: inset 0px 0px 2px 0px $shadowColor;
    border-radius: 4px;
    height: 34px;
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html .sntndr input[type=password] {
    font-family: 'Open Sans, Regular', sans-serif;
    position: relative;
    z-index: 0;
    border: none;
    padding: 0 10px;
    font-size: 16px;
    color: $textColorBlack;
    box-shadow: inset 0px 0px 2px 0px $shadowColor;
    border-radius: 4px;
    height: 34px;
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html .sntndr aside.related-content.right {
    width: 40%;
    display: inline-block;
    margin-left: 40px;

    @media screen and (max-width: $desktop-min-size) {
        margin-top: 30px;
        float: none;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

html .sntndr .languages {
    display: inline-block;
}

html .sntndr ul {
    list-style: none;
}

html .sntndr .languages li {
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
}

html .sntndr .languages li + li {
    margin-left: 15px;
    padding-left: 15px;
}

html .sntndr .languages li a {
    text-decoration: underline;
    cursor: pointer;
    color: $whiteColor;
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 14px;
}

html .sntndr .languages.inline {
    width: 67%;
    text-align: right;

    @media screen and (max-width: $desktop-min-size) {
        width: 30%;
    }
}

html .sntndr .languages li.selected a {
    text-decoration: none;
    cursor: default;
    opacity: 0.8;
}

html .sntndr .form-field {
    clear: both;
    display: inline-block;
    width: 100%;
    padding-right: 1px;
}

html .sntndr input[type=text],
html .sntndr input[type=password],
html .sntndr input[type=email],
html .sntndr textarea,
html .sntndr select {
  font-family: 'Lato', sans-serif;
  position: relative;
  z-index: 10;
  border: none;
  padding: 0 10px;
  font-size: 16px;
  color: $textColorBlack;
  box-shadow: inset 0px 0px 2px 0px $shadowColor;
  border-radius: 4px;
  height: 34px;
  line-height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html .sntndr .form-field > * {
    clear: both;
}

html .sntndr .radio-buttons.horizontal {
    display: table;
}

html .sntndr .radio-buttons .radio-option label {
    font-family: 'Arial', Regular, sans-serif;
    font-size: 14px;
    color: #5b5c5b;
}

html .sntndr .radio-buttons.horizontal .radio-option {
    float: left;
}

html .sntndr .radio-buttons.horizontal .radio-option label {
    font-weight: 500;
}

html .sntndr .radio-buttons.horizontal.centered {
    margin:0 auto;
}

html .sntndr .radio-buttons.horizontal .mat-radio-button + .mat-radio-button {
    margin-left: 20px;
}

html .sntndr .radio-buttons input[type=radio] + label {
    padding-left: 5px;
}

html .sntndr .title {
    margin-top: 30px;
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #333333;

    @media screen and (max-width: $desktop-min-size) {
        width: 100%;
        text-align: center;
        font-size: 28px;
    }
}

html .sntndr .title.main-title {
    width: 100%;

    @media screen and (max-width: $desktop-min-size) {
        width: 100%;
        text-align: center;
    }
}

html .sntndr .title.inline {
    display: inline-block;

    @media screen and (max-width: $desktop-min-size) {
        display: block;
    }
}

html .sntndr section.confirmation-section {
    border: 1px solid $greyColor; 
    position: relative;
    float: left;
    margin-top: 30px;
    padding: 40px 30px;
    width: 100%;
    min-height: 360px;
    text-align: center;
    border-radius: 6px;  

    @media screen and (max-width: $desktop-min-size) {
        float: none;
        width: 100%;
        margin-top: 15px;
    }
}

html .sntndr .subtitle {
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 26px;
    font-weight: 100;
    color: #4a4a4a;
    text-align: left;
    margin-bottom: 30px;

    @media screen and (max-width: $desktop-min-size) {
        font-size: 22px;
    }
}

html .sntndr .form-buttons button.disabled {
    background-color: $greyColor;
}

html .sntndr .form-buttons button.disabled:hover {
    background-color: $greyColor;
}

html .sntndr .form-buttons button {
    background-color: $redColor;
    cursor: pointer;
    outline: none;
    background-image: none !important;
    color: $buttonColorWhite;
    box-shadow: none;
    text-transform: none;
    padding: 10px 30px;
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
    height: 45px;
    margin: 0;
    border: 1px solid #e5e5e5;
    font-family: 'Open Sans', Semibold, sans-serif;
    white-space: nowrap;
    border-radius: 25px;
    text-align: center;
    min-width: 280px;
}


html .sntndr .form-buttons button:hover {
    background-color: $darkRedColor;
    cursor: pointer;
    background-image: none !important;
    color: #ffffff;
    box-shadow: none;
    text-transform: none;
    padding: 10px 30px;
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
    height: 45px;
    margin: 0;
    border: 1px solid #e5e5e5;
    font-family: 'Open Sans', Semibold, sans-serif;
    white-space: nowrap;
    border-radius: 25px;
    text-align: center;
    min-width: 280px;
}

html .sntndr .non-autorize-link {
    margin-top: 15px;
    color: #5b89ac;
    text-decoration: underline;
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 13px;
    cursor: pointer;
}

html .sntndr a.remember-link {
    margin-top: 15px;
    color: #5b89ac;
    text-decoration: underline;
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 13px;
    cursor: pointer;
}

html .sntndr a.remember-link:hover {
    margin-top: 15px;
    color: #5b89ac;
    text-decoration: underline;
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 13px;
    cursor: pointer;
}

html .sntndr .left-container {
    width: 55%;
    float: left;

    @media screen and (max-width: $desktop-min-size) {
        float: none;
        width: 100%;
    }
}

html .sntndr .error-feedback-popup {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    margin-top: 30px;
    background-color: #f7dfdf;
    padding: 20px 30px;
    min-height: 110px;
    text-align: center;
    border-radius: 4px;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    z-index: 0;

    @media screen and (max-width: $desktop-min-size) {
        float: none;
    }
}

html .sntndr .error-feedback-popup .error-feedback-icon {
    background: transparent url(./assets/images/Sprite.png) no-repeat scroll -193px 0px;
    width: 28px;
    height: 50px;
    z-index: 0;
    display: inline-block;
    vertical-align: top;
}

html .sntndr .error-feedback-popup .error-feedback-text {
    margin-left: 20px;
    display: inline-block;
    width: 80%;
    vertical-align: middle;

    @media screen and (max-width: $desktop-min-size) {
        float: none;
        vertical-align: top;
    }
}

html .sntndr .error-feedback-popup .error-feedback-text .error-feedback-title {
    font-family: 'Open Sans', Semibold, sans-serif;
    font-size: 14px;
    color: $redColor;
    display: inline-block;
    text-align: left;
    font-weight: 600;
    width: 100%;
}

html .sntndr .error-feedback-popup .error-feedback-text .error-feedback-description {
    font-family: 'Open Sans', Regular, sans-serif;
    font-size: 14px;
    color: #000000;
    display: inline-block;
    text-align: left;
    margin-top: 5px;
    white-space: pre-line;
}

html .sntndr .error-feedback-popup .form-field {
    text-align: left;
}

html .sntndr input[type=text].error, html .sntndr input[type=password].error {
    border: $redColor solid 1px;
}

html .sntndr .loading-div {
    height: 45px;
}

html .sntndr .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $radioButtonRedColor;    
}

html .sntndr .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #818181;
}

html .sntndr .mat-radio-outer-circle {
    border-width: 1px;
}

html .sntndr .mat-radio-button .mat-radio-ripple {
    display: none;
}

html .sntndr .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    min-width: 460px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);

    @media screen and (max-width: $desktop-min-size) {
        min-width: 100%;
    }
}

html .sntndr .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

html .sntndr .modal.right .modal-body {
    padding: 15px 15px 0px;
}

html .sntndr .modal.right.fade .modal-dialog {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}
	
html .sntndr .modal.right.fade.in .modal-dialog {
	right: 0;
}

html .sntndr .modal-content {
    border-radius: 0;
    border: none;
}

html .sntndr .modal-header {
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}

html .feedback-message.align-right {
    margin-left: 30px;

    @media screen and (max-width: $desktop-min-size) {
        margin-left: 0px;
    }
}

label {
    margin-bottom: .5rem;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./assets/fonts/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }