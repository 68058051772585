$textColorBlack: #323232;
$buttonColorWhite: #ffffff;
$redColor: #EC0000;
$darkRedColor: #B30000;
$shadowColor: rgba(0, 0, 0, 0.5);
$radioButtonRedColor: #E72325;
$greyColor: #cccccc;
$whiteColor: #ffffff;
$blueTitleColor: #00B4BB;
$lightBlueColor: #DFEDEE;
$blueContainerBackground: #D9EEF3;
$lightPinkColor: #e69d9d;
$accountComboBackground: #FBFBFB;
$accountComboBorder: #DDDDDD;
$authBackgroundColor : #f6f6f6;
$popupBlueCC : #5b89ac;
$authDescriptionCC : #000000;
$authFailPopUp : #f7dfdf;
$redButton : #989898;
$borderRed : #dddddd;
$popUpYellowColor : #f2f6dc;
$SucessText : #ABC111;
$clientAccountSideBar : #333333;
$dropDownColorRed : #f5f5f5;
$lightRedColor : #878787;
$descriptionClientAccountRed : #4a4a4a;
$bluelightColor : #7094db;
$confirmationDetailRed : #828282;
$footerBackGroundColorRed : #262626;
$loginDescriptionBlueColor : #6F7779;
$smsPopUpRedColor : #e61e25;
$greenColor : #63BA68;
$tppHomeRedColor: #757575;
$tppHomeWhiteColor: #ffeeed;
$tppHomeErrorRed : #a94442;
$comboBoxText: #6D6E6E;
$lighterInfoColor: #F5F9FB;
$lighterInfoTitle: #222222;
$AcessiblePosidon: #137E84;


$desktop-min-size: 1024px; 

$landscape-min-size: 900px; 

$fontSizeLogin: 13px;
$fontSizeLoginSms: 14px;

$fontSizeAuth: 13px;
$fontSizeAuthConfirm: 20px;

$fontSizeAuthFail: 14px;
$fontSizeAuthPending: 14px;
$fontSizeAuthSucess: 14px;

$fontSizeClientAccount: 14px;
$fontSizeClientAccountTitle: 30px;

$fontSizeConfirmationData: 14px;

$fontSizeFooter: 14px;
$fontSizeHeader: 14px;

$fontSizeSepaOperation: 14px;
$fontSizeSwiftOperation: 14px;